html,
body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bp3-heading {
  margin: 0 !important;
}
.bp3-large > .bp3-tab {
  line-height: 50px !important;
}

.bp3-dialog {
  width: auto !important;
}

label.bp3-label .bp3-html-select,
label.bp3-label .bp3-input,
label.bp3-label .bp3-select,
label.bp3-label .bp3-slider,
label.bp3-label .bp3-popover-wrapper {
  margin: 0;
}
