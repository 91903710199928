.css-1hwfws3 {
  padding-left: 0 !important;
  z-index: 10;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0 !important;
}

#dropdown {
  position: relative;
  z-index: 1000;
}
