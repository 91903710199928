:root {
  --main-bg-color: #137cbd;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: var(--main-bg-color) !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--main-bg-color) !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--main-bg-color) !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--main-bg-color);
}
